<template>
  <div v-loading="loading">
    <section>
      <article class="mt-5">
        <div class="container mt-5">

          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item>Pickup Locations</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>

          <h3><b>Pickup Locations:</b></h3>
          <div
            style="display: flex; align-items:center; justify-content: space-between;"
            class="search_add_section"
          >
            <div style="width: 50%;">
              <input
                type="text"
                v-model.trim="filters[0].value"
                placeholder="Search by Name"
                class="search_by_input form-control"
                spellcheck="off"
              />
            </div>
            <div>
              <AddPickupLocationDialog @re-fresh="getPickupLocations" />
            </div>
          </div>
          <hr />

          <div
            v-loading="loading || is_changing"
            class="table-responsive mb-5"
          >
            <data-tables
              :data="pickup_locations"
              :table-props="tableProps"
              :pagination-props="{ pageSizes: [5, 20, 25, 50, 100] }"
              type="expand"
              :filters="filters"
            >

              <div slot="empty">
                <ScaleOut
                  v-if="isLoading && !loadingError"
                  class="mt-3"
                  :background="'#164B70'"
                />
                <div
                  @click="getPickupLocations"
                  v-else-if="!isLoading && loadingError"
                  style="cursor: pointer"
                >
                  Unable to Load Pickup Locations Now. Please click here to retry
                </div>
                <div v-if="!isLoading && !loadingError">No Pickup Locations</div>
              </div>

              <el-table-column
                label="#"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
                width="80px"
              >
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="Pickup Location"
                :show-overflow-tooltip="false"
                label-class-name="text-left"
                class-name="text-left"
              >
                <template slot-scope="scope">
                  <span> {{ scope.row.pickup_title }} </span><br />
                  <small>Add On: {{ formattedDate(scope.row.created_date) }} </small><br />
                </template>
              </el-table-column>

              <el-table-column
                label="Status"
                label-class-name="text-center"
                class-name="text-center"
                width="100px"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex;">
                    <el-tooltip
                      :content="scope.row.is_active ? 'Active' : 'Deactivated'"
                      placement="top"
                    >
                      <el-switch
                        v-model="scope.row.is_active"
                        @change="switchStatus(scope.row.is_active, scope.row.pickup_id)"
                        active-color="#13ce66"
                        inactive-color=""
                      >
                      </el-switch>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

              <el-table-column
                label="Actions"
                :show-overflow-tooltip="false"
                label-class-name="text-center"
                class-name="text-center"
              >
                <template slot-scope="scope">
                  <el-row style="display: flex; align-items:center; justify-content:center;">
                    <el-tooltip
                      content="Edit"
                      placement="top"
                    >
                      <el-button
                        type="warning"
                        :disabled="false"
                        @click="showEditPickLocationData(scope.row)"
                        icon="el-icon-edit"
                        circle
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Delete"
                      placement="top"
                    >
                      <el-button
                        type="danger"
                        :disabled="false"
                        @click="deletePickupLocation(scope.row.pickup_id)"
                        icon="el-icon-delete"
                        circle
                      ></el-button>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>

            </data-tables>
          </div>

        </div>
      </article>
    </section>

    <EditPickupLocationDialog
      :showPickupLocationEditDialog="showPickupLocationEditDialog"
      :pickUpLocationData="pickupLocation_data"
      @closeEditPickupLocationDialog="closeEditPickupLocationDialog"
      @re-fresh="getPickupLocations"
    />

  </div>
</template>

<script>
import ScaleOut from "@/components/scale-out-component.vue";
import AddPickupLocationDialog from "../components/dialogs/add-pickup-location-dialog.vue";
import EditPickupLocationDialog from "../components/dialogs/edit-pickup-location-dialog";
import { format, parseISO } from "date-fns";
export default {
  components: {
    ScaleOut,
    AddPickupLocationDialog,
    EditPickupLocationDialog,
  },

  data() {
    return {
      loading: false,
      isLoading: false,
      is_changing: false,
      showPickupLocationEditDialog: false,
      pickup_locations: [],
      pickupLocation_data: {},
      decoded_token: {},
      decoded_token2: {},
      first_name: "",

      tableData: [],
      tableProps: {
        border: true,
        stripe: true,
      },

      filters: [
        {
          prop: "fullname",
          value: "",
        },
      ],
      loadingError: false,
    };
  },

  computed: {
    formattedDate() {
      return (dateString) => format(parseISO(dateString), "do MMM yyyy hh:mma");
    },
  },

  mounted() {
    this.getPickupLocations();
  },

  methods: {
    showEditPickLocationData(pickUpsData) {
      this.showPickupLocationEditDialog = true;
      this.pickupLocation_data = pickUpsData;
    },

    closeEditPickupLocationDialog() {
      this.showPickupLocationEditDialog = false;
    },

    async getPickupLocations() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`pickups`);
        if (request.data.success) {
          this.pickup_locations = request.data.pickup_locations;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch pickup_locations now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async deletePickupLocation(pickupId) {
      this.$confirm(
        // eslint-disable-line no-unreachable
        "This will permanently delete this Pickup Location. Do you want to Continue?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            this.deleting = true;
            let request = await this.$http.delete(`pickups/${pickupId}`);
            if (
              request.data.success &&
              request.data.message === "PICKUP_LOCATION_DELETED_SUCCESSFULLY"
            ) {
              this.getPickupLocations();
              this.$notify({
                title: "Success",
                message: "Pickup Location deleted successfully!",
                type: "success",
              });
            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              this.loadingError = true;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred, please try again.",
                type: "error",
              });
            }
            this.$notify({
              title: "Unable to Delete",
              message: "Unable to delete the Pickup Location, Please try again",
              type: "error",
            });
          } finally {
            this.deleting = false;
          }
        })
        .catch(() => {
          //
        });
    },

    

    async switchStatus(activation_status, pickup_id) {
      try {
        this.is_changing = true;
        let request = await this.$http.patch(
          `pickups/change_is_active_status/${pickup_id}`, {
            is_active: activation_status,
          }
        );
        if (
          request.data.success &&
          request.data.message == "PICKUP_IS_ACTIVE_STATUS_CHANGED_SUCCESSFULLY"
        ) {
          this.getPickupLocations();
          this.showSuccessMessage("Success", );
              return this.$notify({
                title: "Success",
                message: "Pickup status changed",
                type: "success",
              });
        } else {
          throw "UNEXPECTED_RESPONSE";
        }
      } catch (error) {
        if (error.message == "Network Error") {
          this.is_changing = false;
          return this.showFailedMessage(
            "Connection failed",
            "A network error occurred, please try again."
          );
        }
        this.loadingError = true;
        this.showFailedMessage(
          "Unable to change Pickup status",
          "An unexpected error occurred, please try again"
        );
      } finally {
        this.is_changing = false;
      }
    },

  },
};
</script>

<style>
.el-form-item {
  margin-bottom: 0px;
}

.el-form--label-top .el-form-item__label {
  float: none;
  display: inline-block;
  text-align: left;
  padding: 0 0 0px;
}
label {
  display: inline-block;
  margin-bottom: 0px;
}
</style>